import "./style/App.css";
import About from "./About";

function App() {
  return (
    <div className="App">
      <About />
    </div>
  );
}

export default App;
